import {
  type FC,
  type DetailedHTMLProps,
  type TableHTMLAttributes,
} from 'react';
import { cn } from '@/lib/classNames';
import { TableBody } from '@/components/ui/Table/TableBody';
import styles from './Table.module.scss';

type TableElementProps = DetailedHTMLProps<
  TableHTMLAttributes<HTMLTableSectionElement>,
  HTMLTableSectionElement
>;

type CustomProps = {
  rows?: number;
};

export type TablePlaceholderProps = TableElementProps & CustomProps;

export const TablePlaceholder: FC<TablePlaceholderProps> = (
  props,
) => {
  const {
    className, rows, ...rest
  } = props;
  const classes = cn(className);

  return (
    <TableBody {...rest} className={classes}>
      {Array.from({ length: rows || 5 }, (_, el) => (
        <tr key={el} className={styles.tableRowPlaceholder}>
          <td colSpan={100} className="position-relative overflow-hidden padding-1">
            <div className={styles.tableCellPlaceholder} />
          </td>
        </tr>
      ))}
    </TableBody>
  );
};
